<template>
    <el-cascader :options="options" :value="region_" @change="regionChange" :separator="' '"></el-cascader>
</template>
<script>
    import options from './country-level2-data';
    export default {
        model: {
            prop: 'region',
            event: 'change',
        },
        props: ['region'],
        data() {
            return {
                //存放城市数据
                options: options,
                // region_: []
            }
        },
        computed: {
            region_:function () {
                if (this.region) {
                    return [this.region.province,this.region.city];
                }
                return [];
            }
        },
        methods: {
            regionChange(region) {
                this.region.province = region[0];
                this.region.city = region[1];
                this.$emit('change', this.region)
            },
        },
    }
</script>