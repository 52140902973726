<template>
    <div>
        <div class="page-header">新增用户</div>
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="110px" class="login-form">
            <div class="block">
                <div class="header">基本信息</div>
                <div style="padding:20px 0;">
                    <el-form-item prop="name" label="客户全称">
                        <el-input v-model="ruleForm.name" placeholder="请输入客户全称" style="max-width:350px;"></el-input>
                        <div>(填写工商注册的全名， 可以和注册认证过的用户匹配)</div>
                    </el-form-item>
                    <el-form-item prop="credit_id_code" label="营业执照号">
                        <el-input v-model="ruleForm.credit_id_code" placeholder="请输入营业执照号" style="max-width:350px;"></el-input>
                    </el-form-item>
                    <el-form-item label="地址">
                        <RegionSelect v-model="ruleForm"></RegionSelect>
                    </el-form-item>
                    <el-form-item label="详细地址">
                        <el-input type="textarea" :rows="3" v-model="ruleForm.addr" style="max-width:400px"></el-input>
                    </el-form-item>
                    <el-form-item label="注册资本">
                        <el-input v-model="ruleForm.reg_capital" placeholder="请输入注册资本" style="max-width:350px;"></el-input>
                    </el-form-item>
                    <el-form-item label="经营范围">
                        <el-input v-model="ruleForm.scope" placeholder="请输入经营范围" style="max-width:350px;"></el-input>
                    </el-form-item>
                    <el-form-item label="网址">
                        <el-input v-model="ruleForm.site" placeholder="请输入网址" style="max-width:350px;"></el-input>
                    </el-form-item>
                </div>
            </div>
            <div v-if="ruleForm.contact && ruleForm.contact.length>0">
                <div v-for="(item,idx) in ruleForm.contact" :key="idx">
                    <div class="block">
                        <div style="padding:30px 0;">
                            <div style="padding:0 30px 15px 30px;" class="flex">
                                <div>联系人{{idx+1}}</div>
                                <div style="text-align:right;" class="flex-1">
                                    <i @click="removeStyle(idx)" style="font-size:22px;color:#016FFF;" class="iconfont icon-icon7"></i>
                                </div>
                            </div>
                            <el-form-item label="姓名" :prop="'contact.'+ idx +'.name'" :rules="rules1.name">
                                <el-input v-model="item.name" placeholder="请输入姓名" style="max-width:350px;"></el-input>
                            </el-form-item>
                            <el-form-item label="职位">
                                <el-input v-model="item.position" placeholder="请输入职位" style="max-width:350px;"></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="手机1" :prop="'contact.'+ idx +'.mobile1'" :rules="rules1.mobile1">
                                <el-input v-model="item.mobile1" placeholder="请输入手机" maxlength="11" style="max-width:350px;"></el-input>
                                <div @click="selectMobile(idx)" class="input-radio">
                                    <span class="label-radio">
                                        <input type="radio" :checked="item.default_mobile==1" name="radio-mobile" :id="item.id" class="regular-radio"/>
                                        <label :for="item.id"></label>
                                    </span>
                                    <span class="label-text" :class="{'active':item.default_mobile==1}">默认联系手机</span>
                                </div>
                            </el-form-item> -->
                            <el-form-item label="手机1" :prop="'contact.'+ idx +'.mobile1'" :rules="rules1.mobile2">
                                <el-input v-model="item.mobile1" placeholder="请输入手机" maxlength="11" style="max-width:350px;"></el-input>
                                <span v-if="idx==0" style="margin-left:20px;">(短信推送时，使用该手机）</span>
                            </el-form-item>
                            <el-form-item label="手机2" :prop="'contact.'+ idx +'.mobile2'" :rules="rules1.mobile2">
                                <el-input v-model="item.mobile2" placeholder="请输入手机" maxlength="11" style="max-width:350px;"></el-input>
                            </el-form-item>
                            <el-form-item label="座机" :prop="'contact.'+ idx +'.tel'" :rules="rules1.tel">
                                <el-input v-model="item.tel" placeholder="请输入座机" style="max-width:350px;"></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="邮箱" :prop="'contact.'+ idx +'.mail'" :rules="rules1.mail">
                                <el-input v-model="item.mail" placeholder="请输入邮箱" style="max-width:350px;"></el-input>
                                <div @click="selectMail(idx)" class="input-radio">
                                    <span class="label-radio">
                                        <input type="radio" :checked="item.default_mail==1" name="radio-mail" :id="idx" class="regular-radio"/>
                                        <label :for="idx"></label>
                                    </span>
                                    <span class="label-text" :class="{'active':item.default_mail==1}">默认联系邮箱</span>
                                </div>
                            </el-form-item> -->
                            <el-form-item label="邮箱" :prop="'contact.'+ idx +'.mail'" :rules="rules1.mail">
                                <el-input v-model="item.mail" placeholder="请输入邮箱" style="max-width:350px;"></el-input>
                                <span v-if="idx==0" style="margin-left:20px;">(邮件推送时，使用该邮箱）</span>
                            </el-form-item>
                            <el-form-item style="margin-bottom:0;" label="微信" :prop="'contact.'+ idx +'.wechat'" :rules="rules1.wechat">
                                <el-input v-model="item.wechat" placeholder="请输入微信" style="max-width:350px;"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block">
                <div @click="addStyle()" style="padding:10px;text-align:center;">
                    <el-button type="text">+ 新增联系人</el-button>
                </div>
            </div>
            <div style="padding: 10px 20px 30px;">
                <el-button @click="hide()" style="width: 75px;" size="small" type="primary" plain>取消</el-button>
                <el-button v-if="product_no" @click="submitForm()" style="width: 75px;" size="small" type="primary">保存</el-button>
                <el-button v-else @click="submitForm()" style="width: 75px;" size="small" type="primary">提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { addCompany,updateCompany,detailCompany } from '@/service/crm';
    import RegionSelect from '@/components/region/Index.vue'
    export default {
        components: {
            RegionSelect,
        },
        data() {
            var checkPhone = (rule, value, callback) => {
                if (value) {
                    const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                } else {
                    callback();
                }
            };
            var checkTel = (rule, value, callback) => {
                if (value) {
                    const reg = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的座机'));
                    }
                } else {
                    callback();
                }
            };
            var checkMail = (rule, value, callback) => {
                if (value) {
                    const reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的邮箱'));
                    }
                } else {
                    callback();
                }
            };
            var checkWechat = (rule, value, callback) => {
                if (value) {
                    const reg = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/;
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的微信号'));
                    }
                } else {
                    callback();
                }
            };
            return {
                rules: {
                    name: [{ required: true, message: '请输入客户全称', trigger: 'blur' }],
                    credit_id_code: [{ required: true, message: '请输入营业执照号', trigger: 'blur' }],
                },
                rules1: {
                    name: [{ required: true, message: '请输入联系人名字', trigger: 'blur' }],
                    mobile1: [{validator: checkPhone, trigger: 'blur'}],
                    mobile2: [{validator: checkPhone, trigger: 'blur'}],
                    tel: [{validator: checkTel, trigger: 'blur'}],
                    mail: [{validator: checkMail, trigger: 'blur'}],
                    wechat: [{validator: checkWechat, trigger: 'blur'}],
                },
                ruleForm:{
                    comp_type:1,
                    name:null,
                    credit_id_code:null,
                    province:null,
                    city:null,
                    addr:null,
                    site:null,
                    reg_capital:null,
                    scope:null,
                    contact:[
                        {
                            name:null,
                            position:null,
                            mobile1:null,
                            mobile2:null,
                            tel:null,
                            mail:null,
                            wechat:null,
                            default_mobile:1,
                            default_mail:1,
                        }
                    ],
                },
                mobileIdx:null,
                mailIdx:null,
                product_no:null,
            }
        },
        created() {
            this.ruleForm.comp_type = this.$route.query.type;
            this.product_no = this.$route.query.id;
        },
        mounted() {
            if (this.product_no) {
                detailCompany({id:this.product_no}).then(rst => {
                    if (rst) {
                        this.ruleForm = {
                            id:rst.id,
                            comp_type:rst.comp_type,
                            name:rst.name,
                            credit_id_code:rst.credit_id_code,
                            province:rst.province,
                            city:rst.city,
                            addr:rst.addr,
                            site:rst.site,
                            reg_capital:rst.reg_capital,
                            scope:rst.scope,
                            contact:rst.contact,
                        };
                    }
                    console.log(rst);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            }
        },
        methods: {
            // selectMobile(index) {
            //     if (this.ruleForm.contact[index].mobile1) {
            //         // this.mobileIdx = index;
            //         this.ruleForm.contact.forEach((e,idx)=>{
            //             if (index==idx) {
            //                 e.default_mobile=1;
            //             } else {
            //                 e.default_mobile=0;
            //             }
            //         })
            //         // this.ruleForm.contact[index].default_mobile=1;
            //     } else {
            //         this.$message.error("请填写手机号");
            //     }
            // },
            // selectMail(index) {
            //     if (this.ruleForm.contact[index].mail) {
            //         // this.mailIdx = index;
            //         this.ruleForm.contact.forEach((e,idx)=>{
            //             if (index==idx) {
            //                 e.default_mail=1;
            //             } else {
            //                 e.default_mail=0;
            //             }
            //         })
            //         // this.ruleForm.contact[index].default_mail=1;
            //     } else {
            //         this.$message.error("请填写邮箱");
            //     }
            // },
            // 添加款式
            addStyle() {
                var obj = {
                    name:null,
                    position:null,
                    mobile1:null,
                    mobile2:null,
                    tel:null,
                    mail:null,
                    wechat:null,
                    default_mobile:0,
                    default_mail:0,
                };
                this.ruleForm.contact.push(obj);
                console.log(this.ruleForm.contact);
            },
            removeStyle(index) {
                this.$confirm('确认是否删除联系人?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    this.$message({ type: 'success', message: '删除成功!' });
                    this.ruleForm.contact.splice(index,1);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            hide() {
                this.$router.push("/client/khgl?type="+this.ruleForm.comp_type);
            },
            submit() {
                if (this.ruleForm.contact.length>0) {
                    this.ruleForm.contact.forEach((e,idx)=>{
                        if (idx==0) {
                            e.default_mobile=1;
                            e.default_mail=1;
                        } else {
                            e.default_mobile=0;
                            e.default_mail=0;
                        }
                    })
                }
                if (this.product_no) {
                    updateCompany(this.ruleForm).then(rst => {
                        this.hide();
                        this.$message.success("修改成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else {
                    addCompany(this.ruleForm).then(rst => {
                        this.hide();
                        this.$message.success("新增成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped>
    .list>div {
        padding: 5px 0;
    }
    div>>> .el-form-item__label {
        font-weight:600;
    }
    .input-radio {
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
    }
    .label-radio {
        position: relative;
        top: 2px;
        margin-right: 8px;
        margin-left: 12px;
    }
    .label-text {
        font-size: 14px;
        color: #4D4D4D;
    }
    .label-text.active {
        color: #5074EE;
    }
    
</style>